/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * October 2024
 */

.fdpb{
    border-radius:1rem;
    height: 5px;
    --background:var(--ion-color-medium);
}
.fdpb-content{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 2rem;
    margin-top: 1rem;
}

.fpdb-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    background-color: var(--ion-color-medium);
    color: var(--ion-color-medium-contrast);
    font-size: 1.25rem;
    position: absolute;
    right: 1rem;
}
.fpdb-icon{
    background-color: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
}