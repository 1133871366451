/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2022
 */

.pdp-toolbar{
    --border-width: 0px;
    --border-color: transparent;
    --border-style: none;
}

.pdp-form{
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
}

.fab-camera-btn{
    position: absolute;
    bottom: -12px;
    right: -23px;
}
