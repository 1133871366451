/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2023
 */

.sp-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.5rem;
}

.sp-icon{
    margin-right: 0.5rem;
    --color: var(--ion-color-dark);
    color: var(--ion-color-dark);
    align-self: start;
    font-size: 20px;
}

@media (prefers-color-scheme: dark) {
    .sp-icon{
        --color: var(--ion-color-primary);
        color: var(--ion-color-primary);
    }
}