/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * July 2022
 */

.image-selector-btn{
    display: flex;
    flex-direction: column;
    border-color: var(--ion-color-medium);
    border-width: 2px;
    border-style: dashed;
    padding: 1.5rem;
    cursor: pointer;
}
.image-selector-thumbnail{
    cursor: pointer;
    object-fit: contain;
}
