#form-checkout-cvv {
    display: flex;
    flex-direction: column;
    max-width: 600px;
}

.container {
    height: 30px;
    display: inline-block;
    border: 0px solid rgb(118, 118, 118);
    border-radius: 2px;
    padding: 1px 2px;
    background-color: transparent;
    color: white !important;
}
.container *{
    color:white !important;
}
#cardPaymentBrick_container{
    margin-left: calc(.50rem + 2px);
    margin-right:  calc(.50rem + 2px);
}
