/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * June 2023
 */

.segment-main-page{
    --indicator-color: var(--ion-color-dark);
}

.segment-main-page.ios{
    --color: var(--ion-color-dark);
    --color-checked: #fff;
    /*--border-radius: 20px;*/
}

@media (prefers-color-scheme: dark) {
    .segment-main-page{
        --indicator-color: var(--ion-color-primary);
    }

    .segment-main-page.ios{
        --color: var(--ion-color-primary);
    }
}
.nbio-pl-swiper-navigation{
    width: 100%;
    position: absolute;
    z-index: 10;
    top: calc( 50% - 22px);
    display: flex;
    justify-content: space-between;
    height:0px;
}