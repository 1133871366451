/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * May 2022
 */
.product-title-web{
    font-size:20px;
    line-height: initial !important;
    color:black;
    text-transform: capitalize;
}
.product-title{
    height: 3rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color:black;
    vertical-align: center;
    text-transform: capitalize;
    line-height: 16px;
}
.ios .product-price ion-card-title{
    font-size: 20px !important;
}
.ios .product-price ion-note{
    font-size: 14px !important;
}
.product-price{
    height: 3rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: flex;
    align-items: last baseline;
    flex-wrap: wrap;
}
.product-price-color{
    --color: var(--ion-color-dark);
}
.product-skeleton{
    min-height: 165px;
}
.p-discount-tag{
    background-color: var(--ion-color-danger);
    color: var(--ion-color-danger-contrast);
    font-weight: bold;
    position: absolute;
    left: 0;
    top:0;
    width: auto;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    border-bottom-left-radius: 0;
    -moz-border-radius-topleft: 0.25rem;
    -moz-border-radius-bottomleft: 0;
}
.p-share{
    font-weight: bold;
    position: absolute;
    right: 0;
    top:0;
    width: auto;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    border-bottom-left-radius: 0;
    -moz-border-radius-topleft: 0.25rem;
    -moz-border-radius-bottomleft: 0;
}
.wrapper-img-product{
    position: relative;
}
.wrapper-btn-add-to-cart{
    position: absolute;
    right: 10px;
    bottom: 5px;
}
.product-bullet-points{
    padding-left: 0;
    margin-top: 1.5rem;
}
.product-stars{
    display: flex;
    flex-direction: row;
    height: 32px;
    align-items: center;
}
.product-category-start-wrapper{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    height: 64px;
}
.product-brand-label{
    text-transform: uppercase;
    text-decoration: underline;
    --color: var(--ion-color-dark);
    color: var(--ion-color-dark);
}
.product-dummy-div{
    height: 17px;
}
.hr-custom{
    height: 2px;
    /*background-color: var(--ion-border-color);*/
    background-color: rgba(0, 0, 0, 0.13);
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.wrapper-triggers-popovers{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-flow: wrap;
}
@media (max-width:  991px) {
    .product-discount{
        /*line-height: 21px*/
    }
}

@media (prefers-color-scheme: dark) {
    .product-price-color{
        --color: var(--ion-color-primary);
    }
    .product-brand-label{
        --color: var(--ion-color-primary);
        color: var(--ion-color-primary);
    }
    .hr-custom{
        background-color: var(--ion-border-color);
    }
}