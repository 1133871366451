/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * October 2021
 */

/*-----------------------------------------------------------*/
/*--------Text-wrap-auto-------------------------------------*/
/*-----------------------------------------------------------*/
.text-wrap-wrap{
}
/*-----------------------------------------------------------*/
/*--------Custom toolbar style--------------------------------*/
/*-----------------------------------------------------------*/
.toolbar-nbio{
    --ion-background-color: var(--ion-color-dark);
    --color: white;
}
@media (prefers-color-scheme: light) {
    .ios .toolbar-nbio{
        --background: var(--ion-color-dark);
    }
    .ios .toolbar-nbio ion-button{
        --color: white;
    }
    .ios .toolbar-nbio ion-back-button{
        --color: white;
    }
}
/*-----------------------------------------------------------*/
/*--------Btn on Dark primary/ Light dark--------------------*/
/*-----------------------------------------------------------*/
.btn-on-dark-primary{
    --background: var(--ion-color-dark);
    --color: var(--ion-color-dark-contrast);
}
.btn-on-dark-primary-clear{
    --color: var(--ion-color-dark);
}
.btn-on-dark-primary-outline{
    --color: var(--ion-color-dark);
    --border-color: var(--ion-color-dark)
}
/*-----------------------------------------------------------*/
/*--------Btn Dark (always)----------------------------------*/
/*-----------------------------------------------------------*/
.btn-true-dark{
    --background: var(--ion-color-dark);
    --color: var(--ion-color-dark-contrast);
}
/*-----------------------------------------------------------*/
/*--------Logo btn component---------------------------------*/
/*-----------------------------------------------------------*/
.btn-toolbar-logo{
    background-image: url('../assets/logo-icon-small-dark-mode.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: auto;
    width: 40px;
}

/*-----------------------------------------------------------*/
/*--------GUEST CHECKOUT    ---------------------------------*/
/*-----------------------------------------------------------*/
.contrast-bg{
    background-color:#f4f4f4;
    overflow: auto;
}
@media (prefers-color-scheme: dark) {
    /* ---- Comment/ Remove Various Style Initialization --- */
    .contrast-bg{
        background-color: #282828;
        overflow:auto;
    }
}
/*-----------------------------------------------------------*/
/*--------Main logo image---------------------------------*/
/*-----------------------------------------------------------*/
.main-app-logo{
    background-image: url('../assets/logo_full.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 75px;
    width: 100%;
}
@media (min-width:  768px) {
    .main-app-logo{
        height: 150px;
    }
}
.main-app-logo-sm{
    background-image: url('../assets/logo_full.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 75px;
    width: 30%;
    margin-right: auto;
    margin-left: auto;
}
@media (min-width:  768px) {
    .main-app-logo-sm{
        height: 150px;
    }
}
/*-------------------------------------------------------------------*/
/*--------Logo Icon Invitation Login---------------------------------*/
/*-------------------------------------------------------------------*/
.logo-icon-invitation{
    background-image: url('../assets/logo-icon-small.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 100px;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
    filter: grayscale(100%);
}
/*--------------------------------------------------------*/
/*--------Header divider----------------------------------*/
/*--------------------------------------*-----------------*/
.divider-gradient{
    background: var(--ion-color-secondary);
    background-color: var(--ion-color-secondary);
    background-image: linear-gradient(to right,var(--ion-color-secondary),var(--ion-color-secondary) 33.33%,
    var(--ion-color-primary) 33.33%,var(--ion-color-primary) 66.66%,var(--ion-color-danger) 66.66%,var(--ion-color-danger) 100%);
    height: 5px;
    width: 100%;
}
/*------------------------------------------------*/
/*--------spacing---------------------------------*/
/*------------------------------------------------*/
.no-margin-horizontal{
    margin-left: 0;
    margin-right: 0;
}
.no-margin-left{
    margin-left: 0px;
}
.margin-bottom-half{
    margin-bottom: 0.5rem;
}
.padding-bottom-half{
    padding-bottom: 0.5rem;
}
.padding-vertical-half{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.padding-half{
    padding: 0.5rem;
}
.padding-half-left{
    padding: 0.5rem;
}
.no-padding-top{
    padding-top: 0;
}
.no-padding-bottom{
    padding-bottom: 0;
}
.no-padding-vertical{
    padding-top: 0;
    padding-bottom: 0;
}
.no-padding-inline-end{
    --inner-padding-end: 0;
}
.no-padding-inner-horizontal{
    --padding-start: 0;
    --inner-padding-start: 0;
    --inner-padding-end: 0;
}
.no-padding-start{
    --padding-start: 0;
}
.margin-horizontal-half{
    margin-left:0.5rem;
    margin-right: 0.5rem;
}
.no-margin-top{
    margin-top: 0;
}
.no-margin-bottom{
    margin-bottom: 0;
}
.mr-1{
    margin-right: 0.25rem;
}
.ml-1{
    margin-left: 0.25rem;
}
.margin-right-half{
    margin-right: 0.5rem;
}
/*------------------------------------------------*/
/*--------width-----------------------------------*/
/*------------------------------------------------*/
.w-100{
    width: 100%;
}
.max-w-50{
    width: 50%;
}
/*------------------------------------------------*/
/*--------height-----------------------------------*/
/*------------------------------------------------*/
.h-100{
    height: 100%;
}
.min-height-100{
    min-height: 100%;
}
.h-50{
    height: 50%;
}
.h-75{
    height: 75%;
}
.h-80{
    height: 80%;
}
.h-90{
    height: 90%;
}
/*--------------------------------------------------*/
/*--------Flexbox-----------------------------------*/
/*--------------------------------------------------*/
.flex-row{
    display: flex;
    flex-direction: row;
}
.flex-col{
    display: flex;
    flex-direction: column;
}
.flex-1{
    flex: 1;
}
.flex-auto{
    flex: auto;
}
/*---------------------------------------------------*/
/*--------Position-----------------------------------*/
/*---------------------------------------------------*/
.p-relative{
    position: relative;
}
/*---------------------------------------------------*/
/*--------Cursor ------------------------------------*/
/*---------------------------------------------------*/
.cursor-pointer {
    cursor: pointer;
}
/*---------------------------------------------------*/
/*--------text decorations---------------------------*/
/*---------------------------------------------------*/
.line-clamp{
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.line-underline{
    text-decoration: underline;
}
.line-through{
    text-decoration: line-through;
}
.no-decoration{
    text-decoration: none;
}
/*---------------------------------------------------*/
/*--------general backgrounds------------------------*/
/*---------------------------------------------------*/
.bg-transparent{
    --background: transparent;
}
/*---------------------------------------------------*/
/*--------images classes-----------------------------*/
/*---------------------------------------------------*/
.img-contain{
    height: auto;
   object-fit: contain;
}
.img-thumbnail{
    min-width: 56px;
    height: 56px;
}
/*---------------------------------------------------*/
/*--------scroll-------------------------------------*/
/*---------------------------------------------------*/
.scroll-x-auto{
    overflow-x:auto;
}
.scroll-y-auto{
    overflow-y:auto;
}
/*----------------------------------------------------------*/
/*--------ion item dupe-------------------------------------*/
/*----------------------------------------------------------*/
.ion-item-dupe{
    padding-top: 10px;
    padding-right: 10px;
    padding-left: 20px;
    border-bottom: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
    background: var(--ion-item-background, var(--ion-background-color, #fff))

}
/*----------------------------------------------------------*/
/*--------Border--------------------------------------------*/
/*----------------------------------------------------------*/
.selected-item-border{
    border: 1px solid var(--ion-color-primary);
}
.border-right{
    border-right: 1px solid;
}
/*----------------------------------------------------------*/
/*--------Swiper--------------------------------------------*/
/*----------------------------------------------------------*/
.swiper-pagination{
    --swiper-pagination-bullet-inactive-color: var(--ion-color-step-200, #cccccc);
    --swiper-pagination-color: var(--ion-color-primary, #3880ff);
}
/*----------------------------------------------------------*/
/*--------Feature Main Page----------------------------------*/
/*----------------------------------------------------------*/
.feature-col{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 80px;
    border-left: 3px solid var(--ion-color-dark);
}
@media (prefers-color-scheme: dark) {
    .feature-col{
        border-left: 3px solid var(--ion-color-primary);
    }
}
.display-block{
    display: block;
}
/*----------------------------------------------------------*/
/*--------Main content margin-------------------------------*/
/*----------------------------------------------------------*/
@media (min-width:  992px) {
    .main-content-margin{
        margin: 5rem;
        margin-top:3rem;
    }
    .margin-top-lg{
        margin-top:3rem;
    }
}
/*----------------------------------------------------------*/
/*--------Product Detail Page-------------------------------*/
/*----------------------------------------------------------*/
.product-detail-label-icons{
    height: 32px;
    line-height: 32px;
    font-size: large;
}
a{
    text-decoration: none !important;
    color: inherit !important;
}