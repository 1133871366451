/*-----------------------------------------------------------*/
/*--------Custom toolbar style--------------------------------*/
/*-----------------------------------------------------------*/
.nbio-menu{
    /*--ion-background-color: var(--ion-color-dark);*/
    /*--color: white;*/
}
@media (prefers-color-scheme: light) {
    .nbio-menu-header{
        --background: var(--ion-color-dark-shade);
        color:white;
        --color:white;
    }
    .nbio-menu-content{
        color:white;
        --color:white;
        --background: var(--ion-color-dark);
    }

}
.nbio-menu-thumbnail-wrapper{
    width: 75px;
    height: 75px;
    padding:5px;
    background-color: rgb(0,178,227);
    background: linear-gradient(135deg, rgba(0,178,227,1) 4%, rgba(42,125,225,1) 50%, rgba(243,39,53,1) 99%);
    background-image: linear-gradient(135deg, rgba(0,178,227,1) 4%, rgba(42,125,225,1) 50%, rgba(243,39,53,1) 99%);



}
.nbio-menu-thumbnail{
    width: 100%;
    height: 100%;
    border-radius: 100%;
}
.nbio-menu-profile{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    padding-bottom: 10px;
    border-bottom:  1px solid rgba(255, 255, 255, 0.75);

}
.nbio-menu-profile-content{
    padding-left:1rem;
    max-width: calc( 100% - 1rem - 75px);
}
.nbio-menu-category-name{
    padding: 0.5rem 0;
    border-bottom:  1px solid rgba(255, 255, 255, 0.33);
    cursor: pointer;
}
.ripple-parent {
    position: relative;
    overflow: hidden;
}
.border-radius-100{
    border-radius: 100%;
}
.nbio-menu-subtitle{
    display: block;
    border-bottom:  1px solid rgba(255, 255, 255, 0.75);
    margin-top: 1.75rem;
}
.nbio-menu-need-help-text{
    padding: 0.5rem 0;
}
.cursor-pointer{
    cursor:pointer;
}