/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * March 2022
 */

.web-footer{
    border-top: 1px solid var(--ion-color-light);
    margin-top: 5rem;
}
.web-footer-section-top{
    padding: 3rem;
}
.web-footer-section-top-small{
    padding-left:3rem;
}
.web-footer-sp{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right:0.5rem;
}
.web-footer-sp-icon{
    font-size: 80px;
    margin-right: 1rem;
}
.web-footer-sp-icon-small{
    font-size: 30px;
    margin-right: 1rem;
}
.web-footer-section-bottom{
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: var(--ion-color-light);
    color: var(--ion-color-light-contrast);
}
.web-footer-logo{
    width: 50px;
    height: 50px;
    background-image: url("../../assets/logo-icon-small-dark-mode.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    margin-bottom: 0.5rem;
    margin-left: auto;
    margin-right: auto;
}
.web-footer-wrapper-download-btns{
    display: flex;
    padding-top: 1rem;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.web-footer-wrapper-icons{
    display: flex;
    padding-top: 1rem;
    flex-wrap: wrap;
    justify-content: center;
}
.web-footer-icon{
    color: inherit;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    margin-right: 0.75rem;
}

.web-footer-icon:hover{
    text-decoration: underline !important;
}

.web-footer-icon:last-child{
    margin-right: 0;
}

.download-btn{
    --background: white;
    --color: #1f1646;
}

.download-btn-label{
    display: flex;
    flex-direction: column;
    padding-left: 0.5rem;
    align-items: start;
}

.download-btn-label small{
    font-size: 0.6rem;
}

.web-footer-nodeport{
    margin-top: 1rem;
}

@media (prefers-color-scheme: light) {
    .web-footer-section-bottom{
        background-color: var(--ion-color-dark);
        color:var(--ion-color-dark-contrast) ;
    }
}