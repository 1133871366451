.swiper-pagination-bullet{
    width: 0.75rem;
    height: 0.75rem;
    display: inline-block;
    border-radius: 100%;
    margin-left: 0.5rem;
    opacity: 1;
    background-color: white !important;
}
.swiper-pagination-bullet-active{
    background-color: #007aff !important;
}