/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * June 2023
 */

.category-card{
    border-radius: 0;
    box-shadow: none;
    transform: none;
    border: none;
}

.category-content{
    background-color: rgba(0,0,0,0.3);
    position: absolute;
    top:0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 1rem;
    color:white;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: start;
}
.nbio-swiper-navigation{
    width: 100%;
    position: absolute;
    z-index: 10;
    top: calc( 50% - 22px);
    display: flex;
    justify-content: space-between;
    height:0px;
}
