/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * May 2022
 */

.w-breadcrumb{
    cursor: pointer;
    text-decoration: underline;
    text-transform: capitalize;
}
.w-breadcrumb:after {
    content: " > ";
}
.w-breadcrumb:last-child:after {
    content: " ";
}
.w-breadcrumb:hover{
    color: var(--ion-color-primary);
}
