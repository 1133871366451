/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * December 2022
 */
.coupon{
    display: flex;
    flex-direction: column;
    min-height:  140px;
    border-radius: 5px;
    background: linear-gradient(135deg,  var(--ion-color-primary), var(--ion-color-secondary));
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
    position: relative;
    color: white;
    cursor: pointer;
}

.coupon.small{
    min-height:  100px;
}

.coupon-body{
    flex: 1;
    padding: 10px 1rem 0 1rem;
}
.coupon-footer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 2px dashed white;
    padding: 5px 1rem;
}
.coupon-title{
    color: var(--ion-color-danger);
    text-stoke: 1.3px white;
    -webkit-text-stroke: 1.3px white;
    font-size: 1.75rem;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
}
.coupon-subtitle{
    font-size: small;
}
.coupon-tiny-btn{
    color: #1f1646;
    cursor: pointer;
    font-weight: bold;
}
.circle1, .circle2{
    background: var(--ion-background-color);
    width: 25px;
    height: 25px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.circle1{
    left: -12.5px;
}
.circle2{
    right: -12.5px;
}
