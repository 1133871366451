/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * March 2022
 */

.top-header{
    --color: white;
}

.web-header-toolbar{
    --min-height: 70px;
    --ion-background-color: var(--ion-color-dark);
    --color: white;
}
.ios .web-header-toolbar {
    --min-height: inherit;
}
@media (prefers-color-scheme: light) {
    .ios .web-header-toolbar{
        --background: var(--ion-color-dark);
    }
    .ios .web-header-toolbar ion-button{
        --color: white;
    }
    .ios .web-header-toolbar ion-back-button{
        --color: white;
    }
    .navigation-bar{
        background-color: var(--ion-color-dark);
    }
    .top-header{
        background-color:  white;
    }
}
@media (prefers-color-scheme: dark) {
    .navigation-bar{
        background-color: var(--ion-toolbar-background);
    }
    .top-header{
        background-color:  black;
    }
}
.web-header-nbio{
    background-image: url('../../assets/nbio-name-dark-mode.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: auto;
    width: 60px;
}
/*todo: remove nbio links its ok*/
.web-header-title{
    padding-left: 0.5rem;
}

.web-header-badge{
    position: absolute;
    border-radius: 8px;
    left: calc(50% + 6px);
    top: 0px;
    min-width: 12px;
    font-size: 8px;
    --padding-start: 2px !important;
    --padding-end: 2px !important;
    --padding-top: 3px !important;
    --padding-bottom: 2px !important;
    z-index: 1 !important;
    -webkit-box-sizing: border-box;
}
.navigation-bar{
    padding: 1rem;
    color:white;
}