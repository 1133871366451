/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * October 2023
 */

ion-searchbar.searchbar-web{
    --background: #f4f5f8 !important;
    --color: #000;
    --placeholder-color: rgba(0,0,0,0.7);
    --icon-color: #000;
    --clear-button-color: rgba(0,0,0,0.9);
}
/*ion-searchbar.ios.searchbar-web {*/
/*    --cancel-button-color: rgba(0,0,0,0.9);*/
/*}*/

/*ion-searchbar.md.searchbar-web {*/
/*    --cancel-button-color: rgba(0,0,0,0.9);*/
/*}*/
.search-bar-results{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    width: 100vw;
}
.search-bar-results-closed{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 0vh;
    width: 0vw;
}
.search-results{
    position: absolute;
    top:103px;
    background-color: #f4f5f8;
    color: black;
    z-index: 10;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    overflow: hidden;
}
.search-result-item{
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid rgba(0,0,0,0.13);
}
.search-result-image{
    cursor: pointer;
    width: 80px;
    height: 80px;
    margin-right: 1rem;
}
.search-result-text{

}
.searching-label{
    font-size:24px;
    margin-right: -60px;
    color: var(--ion-color-primary);
}
.search-results-scroll{
    overflow-y: auto;
    height: 339px;
}