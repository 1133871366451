/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * May 2023
 */

.modal-auto-h{
    width: auto;
    min-height: 300px;
}

.ion-modal-add-to-cart{
    --height: auto;
}

.card-content{
    margin: 0;
    margin-top: -1rem;
}
.card-footer{
    margin: 0;
    border-radius: 0;
    display: flex;
    flex-direction: row;
}
