/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * October 2021
 */

.login-logo {
  width: auto;
  height: 150px;
  margin-top: 1rem;
}
.social-sign-buttons{
  display: flex;
  justify-content: center;
}
.social-button{
  --background: black;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1em;
  --padding-start:0;
  --padding-end: 0;
  --padding-top:0;
  --padding-bottom: 0;
}
.social-button:last-child{
  margin-right: 0;
}
.social-button .social-icon{
  color: white;
  margin: 0;
}
.apple-icon{
  background-image: url("../../assets/apple-signin-light.png");
  background-size: 44px 44px;
  width: 100%;
  height: 100%;
}
@media (prefers-color-scheme: dark) {
  .social-button .social-icon {
    color:black;
  }
  .apple-icon{
    background-image: url("../../assets/apple-signin-dark.png");
  }
  .social-button{
    --background: white;
  }
}
