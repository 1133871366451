/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2023
 */

.header-product-details-mobile{
  /*background-color: pink;*/
  width: 100%;
  max-height: 110px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
  padding:0.5rem;
}

.hpdm-title{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex:1;
  overflow-y: hidden;
}

.hpdm-link{
  height: fit-content;
}

.hpdm-content{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;
}

.sticky {
  position: fixed;
  top: 75px;
  width: 100%;
}

@media (prefers-color-scheme: dark) {
  .header-product-details-mobile{
    border-bottom: 1px solid rgba(255, 255, 255, 0.33);
  }
}