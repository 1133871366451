/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * May 2022
 */

.o-widget{
    border: 3px solid var(--ion-color-primary);
    --web-box-shadow: 0 3px 1px -2px rgba(var(--ion-color-primary-rgb), 0.2), 0 2px 2px 0 rgba(var(--ion-color-primary-rgb), 0.14), 0 1px 5px 0 rgba(var(--ion-color-primary-rgb), 0.12);
    box-shadow: 0 3px 1px -2px rgba(var(--ion-color-primary-rgb), 0.2), 0 2px 2px 0 rgba(var(--ion-color-primary-rgb), 0.14), 0 1px 5px 0 rgba(var(--ion-color-primary-rgb), 0.12);
    color:white;
    --color:white;
}

.o-wrapper{
    display: flex;
    flex-direction: column;
    align-items: start;
    overflow: hidden;
    width: 100%;
    padding:0.5rem;
}
@media (min-width:  992px) {
    .o-wrapper{
        display: flex;
        flex-direction: row;
        align-items: start;
        height: 100px;
        overflow: hidden;
        width: 100%;
        padding:1rem;
    }
}

@media (prefers-color-scheme: light) {
    .o-widget{
        border: 3px solid var(--ion-color-dark);
        --web-box-shadow: 0 3px 1px -2px rgba(var(--ion-color-dark-rgb), 0.2), 0 2px 2px 0 rgba(var(--ion-color-dark-rgb), 0.14), 0 1px 5px 0 rgba(var(--ion-color-dark-rgb), 0.12);
        box-shadow: 0 3px 1px -2px rgba(var(--ion-color-dark-rgb), 0.2), 0 2px 2px 0 rgba(var(--ion-color-dark-rgb), 0.14), 0 1px 5px 0 rgba(var(--ion-color-dark-rgb), 0.12);
        color:black;
        --color:black;
    }
}