.ticket-upload-wrapper{
    display: flex;
    flex-direction: column;
}
.ticket-upload-preview{
    width: 100px;
    height: 100px;
}
.thumbnail-wrapper{
    width:500px;
}