/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2021
 */

.order-result{
  --ion-background-color: var(--ion-color-primary);
  --ion-background-color: radial-gradient(circle, var(--ion-color-secondary) 13%, var(--ion-color-primary) 100%);
  background-repeat: no-repeat;
}
.congrats-gif{
  background-image: url("https://nbio.blob.core.windows.net/cdn/congrats.gif");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  min-width: 32px;
}
.mr-2{
  margin-right: 0.25rem;
}
.ml-2{
  margin-left: 0.25rem;
}
.flip{
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: flipH();
  -ms-filter: flipH();
}
.order-result-ion{
  color: var(--ion-color-primary-contrast);
  font-size: 5em;
  height: auto;
  width: 50%;
  min-height: 150px;
  background-image: url("https://nbio.blob.core.windows.net/cdn/perro_nbio.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: auto;
  margin-left: auto;
  margin-top: 3rem;
  margin-bottom: 3rem;
  animation-duration: 0.75s;
  animation-name: animate-pop;
  animation-timing-function: cubic-bezier(.26, .53, .74, 1.48);
  animation-fill-mode: backwards;
  animation-delay: 0.3s;
}

@keyframes  scaleToFade{
  from {
    font-size: 25em;
    color: var(--ion-color-secondary);
  }
  to {
    font-size: 5em;
    color: var(--ion-color-primary-contrast);
  }
}

@keyframes animate-pop {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}