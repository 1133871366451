/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2021
 */

.wrapper-image-prescription{
  width: 100%;
  position: relative;
}
.fab-camera-prescription-btn{
  position: absolute;
  top: -31px;
  right: -40px;
}
.upload-image-btn{
  --padding-top: 1.5rem;
  --padding-bottom: 1.5rem;
}
