/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * October 2023
 */

.cart-popup-wrapper-closed{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 0vh;
    width: 0vw;
}
.cart-popup-wrapper-open{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    width: 100vw;
    overflow: auto;
}
.cart-popup{
    width: 400px;
    min-height: 400px;
    position: absolute;
    top:103px;
    right: 0;
    /*background-color: #f4f5f8;*/
    /*color: black;*/
    z-index: 10;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    overflow: hidden;
    background-color: white;
}
.cart-popup-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 1rem;
}
.cart-popup-scroll{
    overflow-y: auto;
    height: 313px;
}
.cart-popup-footer{
    display: flex;
    flex-direction: row;
    align-items: center;
    /*background-color: pink;*/
    padding: 0.5rem 1rem;
    border-top: 1px solid rgba(0,0,0,0.13);
}


/*.search-result-item{*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    border-bottom: 1px solid rgba(0,0,0,0.13);*/
/*}*/
/*.search-result-image{*/
/*    cursor: pointer;*/
/*    width: 80px;*/
/*    height: 80px;*/
/*    margin-right: 1rem;*/
/*}*/
/*.search-result-text{*/

/*}*/
/*.searching-label{*/
/*    font-size:24px;*/
/*    margin-right: -60px;*/
/*    color: var(--ion-color-primary);*/
/*}*/
@media (prefers-color-scheme: dark) {
    .cart-popup{
        background-color: #1e1e1e;
    }
}