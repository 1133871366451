/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * October 2021
 */
.cart-product-wrapper-btns{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.cart-product-label-price{
    min-width: 36px;
    text-align: center;
}
