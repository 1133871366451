/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * October 2022
 */
.opt-skeleton{
    width: 100%;
    height: 68px;
}
.opt-wrapper{
    display: flex;
    overflow-x: auto;
    padding-bottom: 20px;
    padding-right: 10px;
    width: 100%;
}
.opt-item{
     margin-right:1.2rem;
     position: relative;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

.opt-badge{
    position: absolute;
    top:38px;
    right: -10px;
    --background: white;
    color: black;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    --box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    min-width: 20px;
}
.opt-thumbnail{
    --border-radius: 4px;
}
.opt-img{
    width: 48px;
}
