/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * February 2022
 */
.ad-overflow-hidden{
    --overflow: hidden;
}
.ad-popup-image{
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
}
.ad-popup-close-btn{
    position: absolute;
    top:40px;
    right: 10px;
}
.ad-popup-btn{
    position: absolute;
    bottom: 50px;
    left: calc(50% - 48px);
}
